import './footer.scss';

import * as React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="footer-wrapper">
      <section className="app-container footer-copyright-wrapper">
        <a className="footer-copyright" href="https://schedulelab.io">{`© ${getCurrentYear()} ScheduleLab, LLC`}</a>
      </section>
    </footer>
  );
};

function getCurrentYear(): number {
  return new Date().getFullYear();
}

export default Footer;
