import 'components/faq.scss';
import '@schedulelab/scss/buttons.scss';

import * as React from 'react';

const Faq: React.FC = () => {
  return (
    <section className="faq-wrapper">
      <div className="faq-container app-container">
        <p className="faq-text">
          Have any questions about building your schedule? Click the link to visit our FAQ page.
        </p>
        <a
          className="contact-content-button button-outline-gray contact-us-button faq-button"
          target="_blank"
          rel="external noreferrer"
          href="https://schedulelab.io/faq"
        >
          FAQ Page
        </a>
      </div>
    </section>
  );
};

export default Faq;
