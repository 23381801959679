import 'pages/index.scss';

import ContactUs from 'components/contact-us';
import Faq from 'components/faq';
import Footer from 'components/footer';
import * as React from 'react';
import { Helmet } from 'react-helmet';

const IndexPage: React.FC = () => (
  <main>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>Try ScheduleLab</title>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="ScheduleLab is the premiere scheduling app for college students! Learn how you can build the perfect schedule in minutes, not hours!"
      />
    </Helmet>

    <Header />
    <ContactUs />
    <div className="flex-grow">
      <HowToSection />
      <Faq />
    </div>
    <Footer />
  </main>
);

const Header: React.FC = () => {
  return (
    <header className="header-wrapper">
      <section className="app-container header">
        <section className="header-phones-wrapper">
          <img
            className="header-background-demo-phone"
            src="https://s3.us-east-2.amazonaws.com/schedulelab.io/public/images/demo/try-schedulelab/pixel-4-completed-outline.png"
            alt="Full Outline on Google Pixel"
          />
          <img
            className="header-foreground-demo-phone"
            src="https://s3.us-east-2.amazonaws.com/schedulelab.io/public/images/demo/try-schedulelab/iphone-12-full-schedule.png"
            alt="Full Schedule on Apple iPhone"
          />
        </section>
        <section className="header-text-wrapper">
          <img
            className="header-text-logo"
            src="https://s3.us-east-2.amazonaws.com/schedulelab.io/public/images/logo/logotype_white.svg"
            alt="ScheduleLab Logo and Icon"
          />
          <p className="header-text-content">
            ScheduleLab is the premiere scheduling app for students across the country! Find time for lunch, figure out
            how to fit in your favorite professor, and even more.
          </p>
          <p className="header-text-content">
            Download ScheduleLab today and plan your semester in minutes, not hours!
          </p>
          <section className="header-download-buttons-wrapper">
            {downloadButtons.map((downloadButton, index) => (
              <a key={index} className="header-download-button" href={downloadButton.downloadLink}>
                <img
                  className="header-download-button-content"
                  src={downloadButton.imageSrc}
                  alt={downloadButton.imageAlt}
                />
              </a>
            ))}
          </section>
        </section>
      </section>
    </header>
  );
};

interface DownloadButton {
  downloadLink: string;
  imageSrc: string;
  imageAlt: string;
}

const downloadButtons: DownloadButton[] = [
  {
    downloadLink: 'https://apps.apple.com/us/app/schedulelab/id1554703951',
    imageSrc: 'https://s3.us-east-2.amazonaws.com/schedulelab.io/public/images/badge/app-store-badge.svg',
    imageAlt: 'Download on the App Store',
  },
  {
    downloadLink: 'https://play.google.com/store/apps/details?id=io.schedulelab.schedulelabforcolleges',
    imageSrc: 'https://s3.us-east-2.amazonaws.com/schedulelab.io/public/images/badge/google-play-badge.svg',
    imageAlt: 'Get it on Google Play',
  },
];

const HowToSection: React.FC = () => {
  return (
    <section className="app-container">
      <section className="how-to-card">
        <section className="how-to-card-header">
          <h2 className="how-to-card-header-content">How To Use ScheduleLab</h2>
        </section>
        {howToSteps.map((howToStep, index) => (
          <section key={index} className={`${index % 2 == 0 ? 'how-to-card-step-even ' : 'how-to-card-step-odd'}`}>
            <section className="how-to-card-step-circle-wrapper">
              <div className="how-to-card-step-circle">
                <p className="how-to-card-step-circle-content">{index + 1}</p>
              </div>
            </section>
            <section className="flex-grow">
              <h3 className="how-to-card-step-header">{howToStep.title}</h3>
              <p>{howToStep.description}</p>
            </section>
          </section>
        ))}
      </section>
    </section>
  );
};

interface HowToStep {
  title: string;
  description: string;
}

const howToSteps: HowToStep[] = [
  {
    title: 'Pick your school',
    description:
      'Choose from an ever-expanding list of colleges, down to the campus. Don’t see your school? You can request it right in the app and have us notify you once it’s added.',
  },
  {
    title: 'Choose your classes',
    description:
      'Start creating a schedule will all the classes from your school’s most recent semesters. ScheduleLab updates courses daily, so you can stay on top of the most current info!',
  },
  {
    title: 'Tell us what you want',
    description:
      'Got a favorite professor? Looking to swap class time with nap time? ScheduleLab’s scheduling system lets you build your semester around your life.',
  },
  {
    title: 'ScheduleLab does the rest!',
    description:
      'ScheduleLab is lightning-fast, giving you your best schedule in minutes, not hours. Find one you like? Add it to your favorites for later.',
  },
];

export default IndexPage;
