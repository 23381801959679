import 'components/contact-us.scss';

import * as React from 'react';

const ContactUs: React.FC = () => {
  return (
    <section className="contact-wrapper">
      <div className="contact-section app-container">
        <p className="contact-content-text">Have a question? Found a bug?</p>
        <a className="contact-content-button button-outline-white" href="mailto:support@schedulelab.io">
          Contact Us
        </a>
      </div>
    </section>
  );
};

export default ContactUs;
